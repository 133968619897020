<template>
  <div :class="$style.container">
    <div :class="$style.title">
      <span :class="$style.text">{{ t('Final step') }}</span>
    </div>
    <div :class="$style.subtitle" v-if="false">
      <span :class="$style.text">
        {{ t('All information can then be changed in the profile settings') }}
      </span>
    </div>
    <form @submit.prevent="onSubmit" :class="$style.form">
      <ui-field
        required
        size="medium"
        v-model="form.username"
        :label="t('Nickname')"
        :error="errors.username"
      />
      <ui-field
        v-if="false"
        required
        size="medium"
        v-model="form.email"
        label="E-mail"
        :error="errors.email"
      />
      <ui-checkbox-field
        required
        size="medium"
        theme="accent"
        :class="$style.agree"
        v-model="form.agree"
      >
        <i18n-t keypath="agree">
          <template #rules>
            <a :href="projectUrl" target="_blank">{{ t('rules') }}</a>
          </template>
        </i18n-t>
      </ui-checkbox-field>
      <ui-button
        :disabled="!form.agree"
        :loading="loading"
        theme="accent"
        fill
        size="large"
        block
      >
        {{ t('Sign Up') }}
      </ui-button>
    </form>
  </div>
</template>

<i18n>
{
  "ru": {
    "Final step": "Завершение регистрации",
    "All information can then be changed in the profile settings": "Всю информацию потом можно изменить в настройках профиля",
    "Nickname": "Никнейм",
    "Sign Up": "Зарегистрироваться",
    "rules": "Правилами Конкурса",
    "agree": "Я прочитал и согласен с {rules}"
  },
  "en": {
    "agree": "I've read and agree with the {rules}",
    "rules": "Contest Rules"
  }
}
</i18n>

<script>
import UiField from '~/components/ui/field'
import UiButton from '~/components/ui/button'
import UiCheckboxField from '~/components/ui/checkbox'
import { useProfileDataStore } from '~/stores/profile/data'

export default {
  components: {
    UiCheckboxField,
    UiButton,
    UiField
  },
  emits: ['close'],
  data() {
    return {
      loading: false,
      form: {
        username: '',
        // email: '',
        agree: false
      },
      errors: {
        username: '',
        email: ''
      }
    }
  },
  methods: {
    showErrors(errors) {
      this.errors = errors
    },
    hideErrors() {
      this.errors = {}
    },
    async onSubmit() {
      if (this.loading) {
        return
      }
      try {
        this.loading = true
        this.hideErrors()
        await this.updateData({ updates: this.form })
        await this.$sdk
          .module('auth')
          .then(({ getAccount }) => getAccount(true))
        this.$emit('close', { success: true })
        this.$analytics.amplitude('registration_finished')
      } catch (err) {
        if (err.response.status === 422) {
          this.showErrors(err.response._data)
        }
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    projectUrl() {
      return this.$normalizePath('/rules', { useHost: true })
    }
  },
  mounted() {
    this.form.username = this.$auth.account.username
    // this.form.email = this.$auth.account.email
    this.form.agree = !!this.$auth.account.profile.agree
  },
  setup() {
    const { t } = useI18n()
    const profileDataStore = useProfileDataStore()
    const { fetchUpdate: updateData } = profileDataStore

    return { t, updateData }
  }
}
</script>

<style lang="scss" module>
.container {
  background: var(--t-app-l2-bg);
  color: #fff;
  width: 50.5em;
  max-width: calc(100vw - 4em);
  padding: 4.8em;
  border-radius: 0.6em;

  &:before {
    content: '';
    //background-color: #FF3EC6;
    position: absolute;
    left: 3.4em;
    top: 4.6em;
    width: calc(100% - 3.4em + 1.6em);
    height: calc(100% - 4.6em + 1.6em);
    z-index: -1;
    border-radius: 2.3em;
  }
  @include down(md) {
    --border-radius: 1.6em;
    border: 0 none;
    min-height: 100%;
    max-width: none;
    padding: 4em;
  }
  @include down(sm) {
    --border-radius: 0.4em;
    width: calc(100vw - 4em);
    padding: 2em;
  }
}
.title {
  text-transform: uppercase;
  margin-bottom: 1.3em;

  .text {
    font-family: var(--primary-font);
    font-weight: 900;
    font-size: 3.6em;
    line-height: 102%;
  }
}
.form {
  margin-top: 2em;
}
.subtitle {
  margin-bottom: 2.6em;

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4em;
    line-height: 135%;
  }
}
.agree {
  margin-top: 2em;
  margin-bottom: 3em;
  color: #bcbcc2;
  user-select: none;
  a {
    color: #72ff9d;
  }
}
</style>
